.search-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.search-breadcrumb {
  list-style-type: none;
  padding: 0;
  margin: 0 0 10px;
}

.search-breadcrumb li {
  display: inline;
  margin-right: 5px;
}

.search-breadcrumb li:not(:last-child)::after {
  content: ">";
  margin-left: 5px;
}

.search-form {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.search-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-grow: 1;
}

.search-field {
  flex: 1 1 calc(16.666% - 10px);
  min-width: 150px;
}

.search-field label {
  display: block;
  margin-bottom: 3px;
  font-weight: bold;
}
.search-field input{
  height: 10%;
}

.search-field select{
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.search-button {
  background-color: #d11313;
  width: 15%;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
}

.search-button:hover{
  background-color: #871818;
}
.search-result-item {
  background-color: aliceblue;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-result-content {
  background-color: aliceblue;
  flex-grow: 1;
}

.create-match-button {
  background-color: #d11313;
  width: auto;
  margin-left: 15px;
}

.search-results {
  overflow-y: auto;
  max-height: 70vh;
  padding: 20px 0;
}

.search-result-buttons button{
  background-color: #d11313;
  margin-left: 15px;
  width: 50%;
  padding: 8%;
  margin: 5%;
}

.create-match-button1 {
  width: 15%;
  padding: 6px 12px;
  font-size: 12px;
  /* background-color: #3498db; */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-result-item {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.search-result-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.search-result-content {
  background-color: transparent;
}

.search-result-content h4 {
  color: #2c3e50;
  margin: 0 0 10px;
  font-size: 1.2rem;
}

.search-result-content p {
  color: #34495e;
  margin: 5px 0;
  font-size: 0.95rem;
}

.search-result-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
}

.search-result-buttons button {
  padding: 8px 15px;
  border-radius: 6px;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  width: auto;
  margin: 0;
}

.search-pagination {
  margin-left: 80%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  gap: 5px;
}

.search-pagination button {
  padding: 8px 12px;
  border: 1px solid #ddd;
  background: white;
  color: #333;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.search-pagination button.active {
  background-color: #3498db;
  color: white;
  border-color: #3498db;
}

.search-pagination button:hover:not(.active) {
  background-color: #f8f9fa;
}

.pagination-arrow {
  font-size: 1.2rem;
  padding: 8px 12px;
}

.search-result-photo {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  flex-shrink: 0;
}

.result-user-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #3498db;
}

.photo-placeholder {
  width: 100%;
  height: 100%;
  background-color: #e0e0e0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #666;
}

.search-result-item {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .search-result-photo {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  
  .photo-placeholder {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .search-results {
    max-height: 60vh;
    padding: 10px;
  }

  .search-result-item {
    padding: 15px;
  }

  .search-result-content h4 {
    font-size: 1.1rem;
  }

  .search-result-content p {
    font-size: 0.9rem;
  }

  .search-result-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .search-result-buttons button {
    width: 100%;
    margin: 5px 0;
  }

  .search-pagination {
    padding: 15px;
    justify-content: center;
  }

  .search-pagination button {
    padding: 6px 10px;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .search-result-item {
    padding: 12px;
    margin-bottom: 10px;
  }

  .search-result-content h4 {
    font-size: 1rem;
  }

  .search-result-content p {
    font-size: 0.85rem;
  }

  .search-pagination{
    margin-left: 50%;
  }
  .search-pagination button {
    padding: 5px 8px;
    font-size: 0.8rem;
  }
}