.register-container {
  margin-left: 10%;
  width: 100%;
  max-width: 600px; /* Adjust the width as needed */
  margin: 5 auto; /* Center the container */
  background-color: #f9f9f9; /* Light background color */
  padding: 20px; /* Padding inside the container */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-height: 80vh; /* Set maximum height to 80% of viewport height */
}
h2 {
  text-align: center; /* Center the heading */
  color: #333; /* Darker color for text */
}

.register-container-heading{
  background-color: hsl(109, 57%, 63%);
  border-radius: 8px;
  padding: 1px;
  margin-bottom: 5%;
}

.register-container-heading h2{
  color: aliceblue;
}

.registerBackBtncontainer{
  background-color: #ee4248;
  width: 25%;
  border-radius: 8px;
  text-align: center;
  padding: 2px;
  display: flex;
  flex-direction: row;
}

.registerBackBtn{
  height:25px;
  color: #f9f9f9;
  margin:5%;
}

.registerBackText{
  color: #f9f9f9;
}
.form-scroll-container {
  max-height: 400px; /* Set maximum height for scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 10px; /* Prevent content overlap with scrollbar */
}

.register-form {
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack children vertically */
}

.submitPwdBtn{
  display: flex;
  margin-left: -20%;
}

.otp-verification {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.otp-verification input {
  margin: 10px 0;
  padding: 10px;
  width: 200px;
}

.otp-verification button {
  background-color: #ee4248;
  color: white;
  border: none;
  margin-right: 20%;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.otp-verification button:hover {
  background-color: #d13b40;
}
.otpverifiedsection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 10%;
}
.happyJourney{
  width:100%;

}
.form-group {
  margin-bottom: 15px; /* Space between form elements */
}

label {
  font-weight: bold; /* Bold text for labels */
  margin-bottom: 5px; /* Space between label and input */
  color: #333;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
select {
  padding: 10px; /* Inner padding for inputs */
  border: 1px solid #ccc; /* Border color */
  border-radius: 4px; /* Rounded corners for inputs */
  font-size: 16px; /* Font size */
  color: #333;
}

.height-input {
  display: flex; /* Use flexbox for height input */
  gap: 10px; /* Space between feet and inches input */
}

.height-input input {
  width: 50%; /* Adjust width for feet and inches input */
}

.checkbox-group {
  display: flex; /* Align items in a row */
  align-items: center; /* Center items vertically */
}

.checkbox-group input {
  margin-left: 10px; /* Space after checkbox */
}

.register-btn {
  
  color: white; /* Text color */
  border: none; /* No border */
  padding: 10px; /* Padding inside button */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  width:200px;
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition */
}

.register-btn:hover {
  background-color: #d13b40; /* Darker background on hover */
}

.error-text {
  color: red; /* Color for error messages */
  text-align: center; /* Center error text */
  margin-top: 15px; /* Space above error message */
}


.register-container h2 {
  color: #333;
}

.error-text {
  color: #ff0000;
}

.registerBackText {
  color: #ffffff;
}

select option {
  color: #333;
}

.next-btn {
  background-color: #ee4248;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.next-btn:hover {
  background-color: #d13b40;
}

.register-btn {
  background-color: #ee4248;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.register-btn:hover {
  background-color: #d13b40;
}

.generatePwdButton {
  background-color: #417ab7;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.generatePwdButton:hover {
  background-color: #2c5a8f;
}