.hero {
  position: relative;
  z-index: 1;
  padding: 40px 32px 0;
  align-items: center;
}

.heroBackground {
  position: relative;
}


.heroBackground::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../assets/hero1.jpeg');
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  z-index: -1;
}

.welcometext, .stats {
  position: relative;
  z-index: 2;
}
.heroContainer{
  /* margin-left: 5%; */
  padding: 5%;
  margin-right: 5%;
  display: flex;
  flex-direction: row;
}
.heroTitle {
  margin-top: 10%;
  color: #000;
  font: 600 36px/52px Inter, sans-serif;
  /* margin: 0 0 36px; */
}

.heroSubtitle {
  color: rgba(13, 13, 13, 0.7);
  font: 600 18px/28px Inter, sans-serif;
  margin: 0 0 36px;
}

.ctaButton {
  margin-left: 35%;
  border-radius: 12px;
  width: 30%;
  background-color: #ee4248;
  color: #fff;
  font: 600 18px/2 Inter, sans-serif;
  padding: 0 40px;
  border: 1px solid #ee4248;
  cursor: pointer;
}
  .stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 64px auto 0;
    max-width: 1200px;
    width: 100%;
  }
    .loggedinContainer {
      background-color: #ece8e8;
      width: 100%;
      max-width: 400px;
      padding: 30px;
      margin: 0 auto;
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    @media screen and (min-width: 768px) {
      .loggedinContainer {
        width: 20%;
        margin-left: 2%;
      }
    }
  .loggedinContainer h3{
    color: #16a249;
  }
  
  .partners {
    padding: 60px 20px;
    background-color: #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
  }
  
  .customerGrid {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    padding: 20px;
  }
  
  .customerImage {
    width: 400px;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .customerImage:hover {
    transform: scale(1.05);
  }

@media screen and (max-width: 768px) {
  .customerGrid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }

  .customerImage {
    height: 250px;
  }
}

@media screen and (max-width: 480px) {
  .customerGrid {
    grid-template-columns: 1fr;
    padding: 10px;
  }

  .customerImage {
    width: 300px;
    height: 250px;
  }
}


  .userOptions {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .userOptions button {
    background-color: #ee4248;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .statItem {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 280px;
    margin: 0 20px 40px;
  }

  .statIcon {
    width: 64px;
    height: 64px;
  }

  .statText {
    display: flex;
    flex-direction: column;
  }

  .statNumber {
    color: #000;
    font: 600 20px/1.6 Inter, sans-serif;
  }

  .statDescription {
    color: rgba(13, 13, 13, 0.7);
    font: 600 12px/16px Inter, sans-serif;
    margin-top: 8px;
  }

  
  .welcometext {
    display: block;
  }
  
  @media screen and (max-width: 768px) {
    .welcometext {
      display: none;
    }
  }
  

.products {
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #e1e1e2;
  text-align: center;
  font: 600 12px/16px Inter, -apple-system, Roboto, Helvetica, sans-serif;
  margin-top: 64px;
}

.productLine {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.productFeature {
  display: flex;
  flex-direction: column;
  width: 280px;
}

.featureImageWrapper {
  position: relative;
  aspect-ratio: 1.153;
  width: 100%;
  padding: 190px 28px 24px;
}

.featureImage {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.featureDescription {
  position: relative;
  z-index: 1;
}

.productImage {
  width: 100%;
  margin-top: 20px;
}

.partners {
  padding: 60px 20px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}
.cta {
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  gap: 44px;
  padding: 32px;
  margin-top: 100px;
}

.ctaImage {
  flex: 1;
  max-width: 50%;
}

.ctaContent {
  flex: 1;
}

.ctaTitle {
  color: #000;
  font: 700 30px/1.4 Inter, sans-serif;
  margin: 0 0 12px;
}

.ctaDescription {
  color: rgba(13, 13, 13, 0.7);
  font: 400 18px/28px Inter, sans-serif;
  margin: 0 0 24px;
}

.ctaButtons {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  margin-left: -8%;
}

.secondaryButton {
  border-radius: 12px;
  background-color: #f4f4f4;
  color: #000;
  font: 600 16px Inter, sans-serif;
  padding: 10px 16px;
  border: 1px solid #acacac;
  cursor: pointer;
}
  .secondaryButton:hover {
    background-color: #823a3a;
  }

.primaryButton {
  border-radius: 12px;
  background-color: #ee4248;
  color: #fff;
  font: 600 16px Inter, sans-serif;
  padding: 10px 16px;
  border: 1px solid #ee4248;
  cursor: pointer;
}

.ctaStats {
  display: flex;
  justify-content: space-between;
}

.statColumn {
  text-align: center;
}

.statNumber {
  color: #000;
  font: 700 36px/1 Inter, sans-serif;
}

.rating {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ratingNumber {
  color: #000;
  font: 700 36px/1 Inter, sans-serif;
}

.ratingStars {
  width: 32px;
}

.features {
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  gap: 44px;
  padding: 32px;
  margin-top: 100px;
}

.featuresContent {
  flex: 1;
}

.featuresTitle {
  color: #000;
  font: 600 30px/1.4 Inter, sans-serif;
  margin: 0 0 32px;
}

.featuresList {
  list-style: none;
  padding: 0;
  margin: 0 0 32px;
}

.featureItem {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
}

.featureIcon {
  width: 24px;
  height: 24px;
}

.featureTitle {
  color: #000;
  font: 600 18px/2 Inter, sans-serif;
}

.learnMoreButton {
  border-radius: 12px;
  background-color: #f4f4f4;
  color: #000;
  font: 600 16px Inter, sans-serif;
  padding: 10px 16px;
  border: 1px solid #acacac;
  cursor: pointer;
}

.learnMoreButton:hover{
  background-color: #8c8888;
}

.featuresImage {
  flex: 1;
  max-width: 100%;
  height: 700px;
}

@media (max-width: 768px) {
  .featuresImage {
    max-width: 300%;
    height: 450px;
  }
}


.testimonials {
  background-color: #f6f6f6;
  text-align: center;
  padding: 32px;
  margin-top: 100px;
}


.testimonialsSubtitle {
  color: rgba(13, 13, 13, 0.7);
  font: 500 18px/2 Inter, sans-serif;
  margin: 0 0 44px;
}

.testimonialGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
}

.testimonialsContainer {
  display: flex;
}

.testimonialCard {
  width: 120px;
}

.testimonialImage {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.testimonialName {
  color: #000;
  font: 500 18px/2 Inter, sans-serif;
  margin: 0;
}

.testimonialProfession {
  color: rgba(26, 26, 26, 0.36);
  font: 400 16px Inter, sans-serif;
  margin: 12px 0 0;
}

.testimonialsTitle {
  text-align: center;
  color: #000;
  font: 700 30px/1.4 Inter, sans-serif;
  margin: 0 0 44px;
}

.pricing {
  background-color: #f6f6f6;
  text-align: center;
  padding: 32px;
  margin-top: 100px;
}

.pricingTitle {
  color: #000;
  font: 700 24px/2 Inter, sans-serif;
  margin: 0 0 12px;
}

.pricingSubtitle {
  color: rgba(13, 13, 13, 0.7);
  font: 500 18px/28px Inter, sans-serif;
  margin: 0 0 24px;
}

.pricingTabs {
  display: flex;
  justify-content: center;
  margin-bottom: 44px;
}

.pricingTab {
  border: none;
  background: none;
  font: 600 18px/2 Inter, sans-serif;
  color: rgba(13, 13, 13, 0.7);
  cursor: pointer;
  padding: 10px 20px;
}

.activeTab {
  color: #000;
  border-bottom: 2px solid #ee4248;
}

.pricingPlans {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.pricingPlan {
  background-color: #f6f6f6;
  border: 1px solid #acacac;
  border-radius: 12px;
  padding: 16px;
  width: 328px;
}

.pricingPlanHighlighted {
  background-color: #fff;
  border: 1px solid #acacac;
  border-radius: 12px;
  padding: 16px;
  width: 328px;
}

.planTitle {
  color: #16a249;
  font: 600 18px/2 Inter, sans-serif;
  margin: 0 0 12px;
}

.planPrice {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 12px;
}

.price {
  color: #000;
  font: 700 30px/1.4 Inter, sans-serif;
}

.pricePeriod {
  color: rgba(13, 13, 13, 0.7);
  font: 400 16px Inter, sans-serif;
}

.planDescription {
  color: rgba(13, 13, 13, 0.7);
  font: 500 16px Inter, sans-serif;
  margin: 0 0 12px;
}

.planFeatures {
  list-style: none;
  padding: 0;
  margin: 16px 0;
  text-align: left;
}

.planFeatureItem {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #000;
  font: 500 16px Inter, sans-serif;
  margin-bottom: 20px;
}

.featureIcon {
  width: 24px;
  height: 24px;
}

.faq {
  background-color: #f6f6f6;
  padding: 32px;
  margin-top: 100px;
}

.faqTitle {
  color: #000;
  font: 700 30px/1.4 Inter, sans-serif;
  text-align: center;
  margin: 0 0 12px;
}

.faqSubtitle {
  color: rgba(13, 13, 13, 0.7);
  font: 500 18px/2 Inter, sans-serif;
  text-align: center;
  margin: 0 0 44px;
}

.faqList {
  max-width: 800px;
  /* margin: 0 auto; */
  width: 70%;
}
  .faqItem {
    margin-bottom: 16px;
    margin-left: 0;
    width: 100%;
  }

  .faqQuestion {
    background-color: #f4f4f4;
    border: 1px solid #acacac;
    margin-left: 5%;
    border-radius: 12px;
    color: #000;
    padding: 20px 24px;
    width: 150%;
    text-align: left;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .faqQuestion:hover{
    background-color: #acacac;
  }
  .faqIcon {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
  }

  .faqAnswer {
    background-color: #f4f4f4;
    border: 1px solid #acacac;
    border-top: none;
    border-radius: 0 0 12px 12px;
    border-color: #5d5757;
    padding: 20px 24px;
    width: 125%;
    margin-left:6%;
    background-color: lightgrey;
  }

  .faqAnswer p {
    color: rgba(13, 13, 13, 0.7);
    font: 400 16px/24px Inter, sans-serif;
    margin: 0;
  }

@media (max-width: 991px) {

  .header,
  .hero,
  .partners,
  .cta,
  .features,
  .testimonials,
  .pricing,
  .faq,
  .footer {
    padding: 20px;
  }

  .heroTitle,
  .ctaTitle,
  .featuresTitle,
  .testimonialsTitle,
  .pricingTitle,
  .faqTitle {
    font-size: 24px;
  }

  .heroSubtitle,
  .ctaDescription,
  .testimonialsSubtitle,
  .pricingSubtitle,
  .faqSubtitle {
    font-size: 16px;
  }

  .ctaButton,
  .loginButton,
  .secondaryButton,
  .primaryButton,
  .learnMoreButton {
    padding: 8px 16px;
    font-size: 14px;
  }

  .stats,
  .products,
  .partners,
  .cta,
  .features,
  .testimonials,
  .pricing,
  .faq,
  .footer {
    margin-top: 40px;
  }

  .cta,
  .features {
    flex-direction: column;
  }

  .ctaImage,
  .featuresImage {
    max-width: 100%;
  }

  .pricingPlans {
    flex-direction: column;
    align-items: center;
  }

  .pricingPlan,
  .pricingPlanHighlighted {
    width: 100%;
    max-width: 328px;
  }

  .footerContent {
    flex-direction: column;
    gap: 20px;
  }

  .footerNavList {
    flex-wrap: wrap;
    justify-content: center;
  }
}