.profile-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    background-color: rgb(236, 185, 194);
    /* background-image: url('../../assets/calltoaction1.jpeg'); */
    background-size: cover;
    background-position:center;
    background-repeat:repeat-y;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  
.profile-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(255, 255, 255, 0.9); */
  border-radius: 15px;
  z-index: 0;
}

.profile-container > * {
  position: relative;
  z-index: 1;
}

.photo-display-box {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 20px auto;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2; /* Fallback background */
}

.profile-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 3;
}

.nav-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.nav-button.left {
  left: 10px;
}

.nav-button.right {
  right: 10px;
}
