.profile-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    /* background-image: url('../../assets/calltoaction1.jpeg'); */
    /* background-image: url('../../assets/img3.png'); */
    background-size: contain;
    background-position:center;
    background-repeat: repeat-y;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
.profile-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
  z-index: 0;
}

.profile-container > * {
  position: relative;
  z-index: 1;
}

  .profile-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
    font-size: 28px;
  }
  
  .profile-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .profile-info h3 {
    grid-column: 1 / -1;
    margin-top: 25px;
    color: #2c3e50;
    font-size: 22px;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
  }
  
  .profile-info p {
    margin: 10px 0;
    font-size: 16px;
    line-height: 1.6;
  }
  
  .profile-info strong {
    color: #34495e;
    font-weight: 600;
  }
  
  .profile-info p:nth-child(even) {
    background-color: #f8f9fa;
    padding: 8px;
    border-radius: 5px;
  }

  .profile-info input,
.profile-info select,
.profile-info textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.profile-info label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.profile-info button {
  background-color: #d11313;
  padding: 10px;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.profile-info button:hover {
  background-color: #2980b9;
}
  
  @media (max-width: 768px) {
    .profile-info {
      grid-template-columns: 1fr;
    }
  }
  
  .profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 auto 20px;
    display: block;
    object-fit: cover;
  }
  
  .edit-profile-btn {
    display: block;
    width: 200px;
    margin: 30px auto 0;
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .edit-profile-btn:hover {
    background-color: #2980b9;
  }
  
  .photo-display-box {
    position: relative;
    width: 300px;
    height: 300px;
    margin: 20px auto;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #3498db;
}

.profile-photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto 20px;
  display: block;
  object-fit: cover;
  z-index: 2;
  position: relative;
}

.nav-button {
  position: absolute;
  top: 50%;
  width: 2%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 5px 8px;
  cursor: pointer;
  z-index: 2;
  font-size: 14px;
}

.nav-button.left {
  left: 0;
  margin-left: 2%;
}

.nav-button.right {
  right: 0;
}

.nav-button:hover {
    background: rgba(0, 0, 0, 0.7);
}

.camera-icon-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #f5f5f5;
  border-radius: 50%;
}

.camera-icon {
  font-size: 48px;
  color: #3498db;
}

.hidden-file-input {
  display: none;
}