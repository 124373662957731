.profiles-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.profiles-title {
    text-align: center;
    color: #333;
    margin-bottom: 2rem;
    font-size: 2rem;
}

.profiles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    padding: 1rem;
}

.profile-card {
    background: white;
    border-radius: 10px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    text-align: center;
}

.profile-card:hover {
    transform: translateY(-5px);
}

.profile-image-placeholder {
    width: 100px;
    height: 100px;
    background: #f0f0f0;
    border-radius: 50%;
    margin: 0 auto 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: #666;
}

.profile-name {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.profile-details {
    margin: 1rem 0;
}

.profile-info {
    margin: 0.5rem 0;
    color: #666;
}

.profile-info span {
    font-weight: bold;
    color: #444;
}

.view-profile-btn {
    background: #ee4248;
    color: white;
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    transition: background 0.2s ease;
}

.view-profile-btn:hover {
    background: #9f282c;
}

@media (max-width: 768px) {
    .profiles-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 1rem;
    }
    
    .profiles-container {
        padding: 1rem;
    }
}

.profile-image {
    width: 120px;
    height: 120px;
    margin: 0 auto 1rem;
    border-radius: 50%;
    overflow: hidden;
}

.avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
