.terms-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    color: #333;
    text-align: center;
  }
  
  ol {
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 15px;
    line-height: 1.5;
  }
  
  p {
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
  }
  