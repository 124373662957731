
.login-container {
  border-radius: 8px;
  /* background-image: url('../../assets/hero1.jpeg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(178, 50, 50, 0.1);
  position: relative;
}

.login-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(0, 0, 0, 0.8); */
  border-radius: 8px;
}

.login-header {
  position: relative;
  z-index: 2;
  margin-bottom: 25px;
}

.modeSelectorContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

.modeSelector {
  border-radius: 8px;
  padding: 4%;
  color: #0f0e0e;
  border: 2px solid #393636;
  background-color: transparent;
  display: flex;
  width: 40%;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.modeSelector:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

h2 {
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  position: relative;
  z-index: 2;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.forgotPwdBtn {
  margin-bottom: 2%;
  background: transparent;
  border: none;
  color: #1f1d1d;
  text-decoration: underline;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

input {
  width: 90%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.1);
  color: #252323;
  position: relative;
  z-index: 2;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

button {
  width: 50%;
  margin-left: 20%;
  padding: 15px;
  background-color: transparent;
  border: 2px solid #ffffff;
  color: rgb(22, 21, 21);
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
}

button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.close-button {
  height: 30px;
  width: 8px;
  cursor: pointer;
  filter: brightness(0) invert(1);
  position: relative;
  z-index: 2;
}

p {
  color: #ff6b6b;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  position: relative;
  z-index: 2;
}

@media (max-width: 500px) {
  .login-container {
      padding: 15px;
      margin: 20px auto;
  }

  h2 {
      font-size: 20px;
  }

  button {
      font-size: 14px;
      padding: 10px;
  }
}
