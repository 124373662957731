.partners {
    background-color: #f8f9fa;
    padding: 10px 0;
  }
  
  .cta {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .ctaTitle {
    font-size: 2.5rem;
    color: #333;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .ctaContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .ctaDescription {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
    margin-right: 20px;

    line-height: 1.8;
    max-width: 800px;
    text-align: justify;
    padding: 20px;
    /* background-color: #ffffff; */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
}

  .ctaDescription:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .featuresList {
    list-style-type: none;
    padding: 0;
  }
  
  .featureItem {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .featureItem a {
    text-decoration: none;
    color: inherit;
  }
  
.featureItem a:hover {
    color: #72228a;
  }
  .featureIcon {
    margin-right: 10px;
    font-size: 1.2rem;
    color: #72228a;
  }
  .ctaButtons {
    display: flex;
    gap: 15px;
    margin-top: 30px;
  }
  
  .primaryButton, .secondaryButton {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .primaryButton {
    background-color: #417ab7;
    width: 150px;
    color: white;
  }
  
  .primaryButton:hover {
    background-color: #0056b3;
  }
  
  .secondaryButton {
    background-color: #f8f9fa;
    color: #007bff;
    border: 1px solid #007bff;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  
  .secondaryButton:hover {
    background-color: #e2e6ea;
  }
  
  .map {
    width: 40%;
  }
  
  .partnersImage {
    width: 100%;
    height: 300px;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    .ctaContent {
      flex-direction: column;
    }
  
    .map {
      width: 100%;
      margin-top: 30px;
    }
  }
  
  .contactModal {
    padding: 20px;
    background-image: url('../assets/hero1.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 300px;
  border-radius: 8px;
  }
  
  
.contactModal p {
  margin-bottom: 15px;
  font-size: 16px;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 2;
}

.contactModal strong {
  color: #ffffff;
  font-weight: 600;
}

.contactModal::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}