.admin-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .admin-title {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 30px;
  }
  
  .section-title {
    color: #34495e;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  
  .ant-table-wrapper {
    margin-bottom: 40px;
  }
  
  .ant-table-thead > tr > th {
    background-color: #3498db;
    color: white;
  }
  
  .ant-table-tbody > tr:hover > td {
    background-color: #f0f8ff;
  }
  
  .action-button {
    margin-right: 10px;
  }
  
  .ant-modal-title {
    color: #2c3e50;
  }
  
  .ant-form-item-label > label {
    color: #34495e;
  }
  
  .ant-btn-primary {
    background-color: #3498db;
    border-color: #3498db;
  }
  
  .ant-btn-primary:hover {
    background-color: #2980b9;
    border-color: #2980b9;
  }
  

  @media screen and (max-width: 768px) {
    .ant-table {
      overflow-x: auto;
    }
  
    .admin-container {
      padding: 10px;
    }
  
    .ant-table-wrapper {
      margin-bottom: 20px;
    }
  
    /* Make table text smaller on mobile */
    .ant-table {
      font-size: 14px;
    }
  
    /* Adjust button sizes for mobile */
    .action-button {
      padding: 4px 8px;
      font-size: 12px;
      margin-right: 5px;
    }
  
    /* Stack buttons vertically on very small screens */
    @media screen and (max-width: 480px) {
      .action-button {
        margin-bottom: 5px;
        display: block;
        width: 100%;
      }
    }
  }
  
  /* Enhanced table styling */
  .ant-table-wrapper {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .ant-table-thead > tr > th {
    white-space: nowrap;
    padding: 12px 8px;
  }
  
  .ant-table-tbody > tr > td {
    padding: 12px 8px;
  }
  
  /* Zebra striping for better readability */
  .ant-table-tbody > tr:nth-child(even) {
    background-color: #f9fafb;
  }