
.password-reset-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('../../assets/img5.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;

}

.password-reset-container form{
  background-color: #2b2828;
  opacity: 0.7;
  padding: 2%;
  border-radius: 5%;
}
.password-reset-form {
  background-color: rgba(143, 121, 121, 0.95);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
}

@media screen and (min-width: 1024px) {
  .password-reset-container {
    background-attachment: fixed;
    padding: 40px;
  }
  
  .password-reset-form {
    margin: 40px auto;
  }
}

.password-reset-title {
  color: #ffffff;
  position: relative;
  /* z-index: 2; */
}

.password-reset-input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.password-reset-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.password-reset-button:hover {
  background-color: #0056b3;
}

.password-reset-message {
  margin-top: 1rem;
  text-align: center;
  color: #28a745;
}

.password-reset-error {
  margin-top: 1rem;
  text-align: center;
  color: #dc3545;
}
