/* stylesmain.module.css */

.footer {
  background-color: #fff;
  padding: 32px 40px 40px;
  margin-top: 100px;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 32px;
  border-top: 1px solid #b3b3b3;
}

.footerLogo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.footerLogoImage {
  width: 32px;
  height: 32px;
}

.footerCompanyName {
  color: #1a1a1a;
  font: 700 16px Inter, sans-serif;
}

.footerNav {
  display: flex;
}

.footerNavList {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 28px;
}

.footerNavItem {
  color: rgba(13, 13, 13, 0.7);
  font: 600 16px Inter, sans-serif;
  text-decoration: none;
}
/* Header container styling */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #ffd1dc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
}

/* Company logo container */
.companyLogocontainer {
  flex: 1;
}

.companyLogo {
  width: 150px;
  cursor: pointer;
}

/* Navigation styling */
.navigation {
  flex: 2;
  display: flex;
  justify-content: center;
}

.navList {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
  margin: 0;
}

.navItem {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 20px;
  transition: color 0.3s ease;
}

.navItem:hover {
  color: #ab1d22;
}

/* ... existing styles ... */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: rgb(225, 180, 180);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


/* Login button styling */
.loginContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.loginButton {
  background-color: #ee4248;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.loginButton:hover {
  background-color: #ab1d22;
}

/* Responsive Design */
@media (max-width: 768px) {
  .header {
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
  }

  .companyLogo {
    width: 120px;
  }

  .navigation {
    margin-top: 10px;
    width: 100%;
  }

  .navList {
    flex-direction: row;
    gap: 10px;
    /* text-align: center; */
    width: 100%;
    display: none;
  }

  .loginButton {
    width: 125px;
    height: 60px;
    text-align: center;
  }
}
